import Vue from 'vue'
import Vuex from 'vuex'


const modules = {}
const requireModule = require.context('.', false, /\.store\.js$/)

requireModule.keys().forEach(fileName => {
  const moduleName = fileName.replace('./', '').replace('.store.js', '')
  modules[moduleName] = {
    namespaced: true,
    ...requireModule(fileName).default
  }
})


Vue.use(Vuex)


export default new Vuex.Store({
  modules
})
