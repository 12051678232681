<template>
  <v-app>
    <v-main>
      <v-progress-linear
        v-if="pageLoading()"
        indeterminate
        class="progress-bar"
      ></v-progress-linear>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'App',
  
  computed: {
    ...mapGetters('pageSettings', ['pageLoading'])
  }
}
</script>


<style lang="scss">
@import '@/styles/main.scss';
</style>
