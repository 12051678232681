import axios from 'axios'

const apiClient = axios.create({
  baseURL: 'http://180api-b8f79e50-f9c3-496c-be47-d5e63527087c.caddiesoft.com',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

/*apiClient.interceptors.request.use(async (config) => {
  if (config.auth !== false) {
    try {
      const token = 'b3lzdGVpbi5za2plc29sQGNhZGRpZXNvZnQuY29tOnhkWkY5SG00c0xwa2tJSWJJb1NVOTE2Qw=='
      config.headers.Authorization = `Basic ${token}`
    } catch (error) {
      console.info('error', error)
    }
  }
  return config
}, (error) => {
  return Promise.reject(error)
})*/

export default apiClient
