<template>
  <div class="issue-list">
    <div class="issue-list__header">
      {{ title }} {{ issues.length }}
    </div>
    <div v-if="!pageLoading()" class="issue-list__body">
      <issue-item
        v-for="issue in issues"
        :key="issue.id"
        :issue="issue"
        :status="status"
      ></issue-item>
    </div>
  </div>
</template>


<script>
import IssueItem from './IssueItem'
import { mapGetters } from "vuex"

export default {
  components: {
    IssueItem
  },

  props: {
    title: String,
    issues: Array,
    status: String,
    count: Number
  },
  
  computed: {
    ...mapGetters('pageSettings', ['pageLoading'])
  },
  
  data() {
    return {
      filteredIssues: []
    }
  }
}
</script>
