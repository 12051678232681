import apiClient from './apiClient'

const getIssues = () => {
  /*const params = {
    allProjects
  }
  return apiClient.get(`rest/api/2/search?jql=filter=12341&fields=id,key,status,labels`, { params })*/
  return apiClient.get(`/`)
}


export default {
  getIssues
}
