
const state = {
  pageLoading: false
}

const getters = {
  pageLoading: (state) => () => {
    return state.pageLoading
  }
}

const actions = {
  setPageLoading ({ commit }, isLoading) {
    commit('SET_PAGE_LOADING', isLoading)
  }
}


const mutations = {
  SET_PAGE_LOADING (state, isLoading) {
    state.pageLoading = isLoading
  }
}


export default {
  state,
  getters,
  actions,
  mutations
}
