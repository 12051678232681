import Vue from 'vue'
import issuesApi from '@/api/issues.api'


const state = {
  issues: []
}


const getters = {
  issues: (state) => () => {
    return state.issues
  },

  issueById: (state) => (id) => {
    return state.issues[id] || null
  }
}


const actions = {
  async loadIssues ({ commit }) {
    const response = await issuesApi.getIssues()
    commit('SET_ISSUES', { issues: response.data.issues })
  }
}


const mutations = {
  SET_ISSUES (state, { issues }) {
    state.issues = []
    state.issues.push(...issues)
  }
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
