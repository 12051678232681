<template>
  <div class="page-wrapper">
    <v-app-bar app class="app-bar">
      <v-chip-group
        id="labels-group"
        v-model="chosenLabels"
        column
        multiple
        v-if="!pageLoading()"
      >
        <span class="v-chip v-slide-item--active v-chip--active v-chip--no-color theme--light v-size--default">
          Choose labels:
        </span>
        <v-chip
          v-for="label in filteredLabels"
          :key="label"
          :value="label"
          :class="`mod-${label}`"
          class="aside-panel__label"
          filter
          outlined
        >
          {{ label }}
        </v-chip>
      </v-chip-group>
      <v-btn
            :loading="pageLoading()"
            :disabled="pageLoading()"
            class="ma-2 mb-4 aside-panel__btn"
            @click="onRefreshBtnClick"
          >
            <span>refresh </span>
            <span class="mdi mdi-reload"></span>
          </v-btn>
    </v-app-bar>
    <v-row justify="space-between">
      <v-col v-for="issues in issuesByStatus" class="issue-list-column"
             :key="issues.status">
        <issue-list
          :title="issues.title"
          :issues="issues.items"
          :status="issues.status"
        ></issue-list>
        <p v-if="issues.status === 'done'" class="done-list-bottom-note">
          We're only showing recently modified issues (less than 2 weeks ago).
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import IssueList from './IssueList'

  export default {
    components: {
      IssueList
    },
  
    computed: {
      ...mapGetters('issues', ['issues', 'issueById']),
      ...mapGetters('pageSettings', ['pageLoading']),
  
      filteredLabels () {
        return this.labels.filter(label => label !== 'advista_board' && label !== 'jira_escalated')
      },
      
      allIssues () {
        let list = this.issues()
        return list
      },
  
      filteredIssues () {
        let list = this.issues().map(issue => {
          let sizeLabel = undefined
          switch (issue.fields.customfield_10004) {
            case 1:
              sizeLabel = 'S'
              break;
            case 2:
              sizeLabel = 'M'
              break;
            case 3:
              sizeLabel = 'L'
              break;
            case 4:
              sizeLabel = 'XL'
              break;
          }
          return {
            ...issue,
            sizeLabel
          }
        })
        if (this.chosenLabels.length) {
          return list.filter(issue => issue.fields.labels.some(label => this.chosenLabels.includes(label)))
        }
        return list
      },
  
      issuesByStatus () {
        return {
          todo: {
            title: 'To Do',
            items: this.filteredIssues.filter(x => x.fields.status.id === '10301' || x.fields.status.id === '10300'),
            status: 'todo'
          },
          inProgress: {
            title: 'In Progress',
            items: this.filteredIssues.filter(x =>  x.fields.status.id === '3'),
            status: 'inProgress'
          },
          done: {
            title: 'Done',
            items: this.filteredIssues.filter(issue =>  issue.fields.status.id === '10000' && new Date(issue.fields.updated).getTime() > this.dateTwoWeeksAgoMs),
            status: 'done'
          }
        }
      }
    },
  
    methods: {
      ...mapActions('issues', ['loadIssues']),
      ...mapActions('pageSettings', ['setPageLoading']),
  
      onLabelsTitleClick () {
        return false
      },
  
      onRefreshBtnClick () {
        this.refreshIssues()
      },
      
      async refreshIssues () {
        try {
          this.setPageLoading(true)
          await this.loadIssues()
          this.allIssues.forEach(issue => {
            issue.fields.labels.forEach(label => {
              if (!this.labels.includes(label)) {
                this.labels.push(label)
              }
            })
          })
        } catch (error) {
          console.info('error', error)
        } finally {
          this.setPageLoading(false)
        }
      }
    },
  
    data() {
      return {
        labels: [],
        chosenLabels: [],
        dateTwoWeeksAgoMs: new Date(Date.now() - 14 * 24 * 60 * 60 * 1000).getTime()
      }
    },
    
    created () {
      this.refreshIssues()
    }
  }
</script>
