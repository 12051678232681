<template>
  <div class="issue-item">
    <a :href="`https://caddiesoft.atlassian.net/browse/${issue.key}`"
       target="_blank"
       class="issue-item__card"
    >
      <div class="d-flex">
        <div class="issue-item__summary flex-grow-1">
          {{ issue.fields.summary }}
        </div>
      </div>
      <div class="issue-item__info">
        <div class="issue-item__info-left">
        <div v-if="issue.sizeLabel" class="issue-item__size badge"
               :class="{
                'badge-s': issue.sizeLabel === 'S',
                'badge-m': issue.sizeLabel === 'M',
                'badge-l': issue.sizeLabel === 'L',
                'badge-xl': issue.sizeLabel === 'XL',
                   }">
            {{ issue.sizeLabel }}
          </div>
          <div class="d-flex issue-item__labels">
            <span v-for="label in filteredLabels"
                  class="issue-item__label"
                  :class="`mod-${label}`"
            >
              {{ label }}
            </span>
          </div>
        </div>
        <div class="issue-item__info-right">
          <div class="issue-item__updated-label">
            <v-chip>{{ issue.lastUpdatedLabel }}</v-chip>
          </div>
          <div class="issue-item__key" :class="{'issue-item__key--done': status === 'done'}">
            {{ issue.key }}
          </div>
          <div class="issue-item__assignee">
            <img :src="issue.fields.assignee.avatarUrls['24x24']"
                 :alt="`${issue.fields.assignee.displayName}`"
                 :title="` ${issue.fields.assignee.displayName}`"
                 class="issue-item__assignee-img">
          </div>
        </div>
      </div>
    </a>
  </div>
</template>


<script>
export default {
  props: {
    issue: Object,
    status: String
  },
  
  computed: {
    issueOlderTwoWeeks () {
      return this.dateIssueUpdatedMs < this.dateTwoWeeksAgoMs
    },
    dateIssueUpdatedMs () {
      return new Date(this.issue.fields.updated).getTime()
    },
    filteredLabels() {
      if (this.issue.fields && this.issue.fields.labels && this.issue.fields.labels.length) {
        return this.issue.fields.labels.filter(x => x !== 'advista_board' && x!== 'jira_escalated')
      } else {
        return []
      }
    }
  },
}
</script>
